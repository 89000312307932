// Generated by Framer (e83dae3)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./v3__Cbn9l-0.js";

const cycleOrder = ["wmH2Ocphl"];

const serializationHash = "framer-1NyBD"

const variantClassNames = {wmH2Ocphl: "framer-v-1dik62g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {QqP3jQNpQ: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "wmH2Ocphl", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 760, intrinsicWidth: 876, pixelHeight: 1520, pixelWidth: 1752, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/MLzz6ZsNKEPinN9h1FzBIQ96qV4.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/MLzz6ZsNKEPinN9h1FzBIQ96qV4.png?scale-down-to=512 512w,https://framerusercontent.com/images/MLzz6ZsNKEPinN9h1FzBIQ96qV4.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/MLzz6ZsNKEPinN9h1FzBIQ96qV4.png 1752w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1dik62g", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"wmH2Ocphl"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1NyBD.framer-1ly7to7, .framer-1NyBD .framer-1ly7to7 { display: block; }", ".framer-1NyBD.framer-1dik62g { height: 760px; overflow: visible; position: relative; width: 876px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 760
 * @framerIntrinsicWidth 876
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerv3__Cbn9l: React.ComponentType<Props> = withCSS(Component, css, "framer-1NyBD") as typeof Component;
export default Framerv3__Cbn9l;

Framerv3__Cbn9l.displayName = "1 Copy";

Framerv3__Cbn9l.defaultProps = {height: 760, width: 876};

addFonts(Framerv3__Cbn9l, [])